<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="15"
    viewBox="0 0 9 15"
    fill="none"
    stroke="currentColor"
  >
    <g clip-path="url(#clip0_31306_214260)">
      <path
        d="M0.763125 0.699219L7.23313 7.22922L0.703125 13.6992"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_31306_214260">
        <rect width="8.65" height="14.41" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
